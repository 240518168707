import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { CurrentUser, User } from '../../model/user.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
// import { firebase } from '@firebase/app';
// import * as firebase from 'firebase/app';

import { tokenGetter } from '../../helpers/tokenGetter.helper';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { Biblist } from '../../model/biblist.model';
import { UUID } from 'angular2-uuid';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { LevelProgress } from '../../model/score.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: Observable<CurrentUser>;
  _currentUser: BehaviorSubject<CurrentUser>;

  map: Observable<string>;
  _map: BehaviorSubject<string>;

  activationUser;

  userCollection: AngularFirestoreCollection<User>;

  // currentFireUser: firebase.User;

  constructor(
    private http: HttpClient,
    private messagingService: PushNotificationService,
    public fireAuth: AngularFireAuth,
  ) {
    this._currentUser = new BehaviorSubject<CurrentUser>(null);
    this.currentUser = this._currentUser.asObservable();
    this._map = new BehaviorSubject<string>(null);
    this.map = this._map.asObservable();
  }

  setUser(data: CurrentUser) {
    this._currentUser.next(data);
    localStorage.setItem('currentUser', JSON.stringify(data));
  }

  forgotPassword(data) {
    return this.http.put(`${$REST_URI}/user/password/forgot`, data);
  }

  resetPassword(data, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('x-auth-token', token);
    console.log(headers.get('x-auth-token'));
    return this.http.put(`${$REST_URI}/user/password/reset`, data, { headers });
  }

  getUser(): CurrentUser | null {
    const temp = localStorage.getItem('currentUser');
    if (temp) {
      const currentUser: CurrentUser = JSON.parse(temp);
      return currentUser;
    } else return null;
  }

  saveUser(data: CurrentUser, token: String) {
    if (data) {
      this.setUser(data);
    }
    if (token) this.setAuthToken(token);
  }

  removeUser() {
    this.setUser(undefined);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userCredentialId');
    this.removeToken();
  }

  setAuthToken(token: any) {
    if (!!token && typeof token === 'string') {
      localStorage.setItem('auth-token', token);
    } else {
      throw new Error(`NO token or malformed, Token: ${token}.`)
    }
  }

  getAuthToken() {
    return tokenGetter();
  }

  removeToken() {
    localStorage.removeItem('auth-token');
  }

  // isAuthenticated() {
  //   var token = this.getAuthToken();
  //   if (token) {
  //     let expired: boolean = this.jwtHelper.isTokenExpired(token);
  //     if (expired) {
  //       console.log('*************  TOKEN EXPIRED  *************')
  //       localStorage.removeItem('auth-token');
  //       return false;
  //     }
  //     return true;
  //   } else
  //     return false;
  // }

  uploadAvatar(file) {
    let formData = new FormData();
    formData.append('avatar', file);
    console.log("image file", file);
    return this.http.post(`${$REST_URI}/user/avatar`, formData);
  }

  downloadAvatar(id) {
    return this.http.get(`${$REST_URI}/client/avatar/${id}`);
  }

  updateProfile(data: User) {
    return this.http.put(`${$REST_URI}/client`, data);
  }

  loginGet(data) {
    let fcmToken;

    this.messagingService.requestPermission()

    console.log("Auth ID:", data.id);
    let params = new HttpParams();
    if (data.id) {
      params = params.append('firebaseUid', data.id);
    }

    if (data.gameId) {
      params = params.append('gameId', data.gameId);
    }

    if (this.messagingService.token) {
      fcmToken = this.messagingService.token;
      console.log("browser Token:", fcmToken);
    }

    if (fcmToken) {
      params = params.append('fcmToken', fcmToken);
    }
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  get(id?: string) {
    let fcmToken;

    this.messagingService.requestPermission()

    console.log("Auth ID:", id);
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }

    if (this.messagingService.token) {
      fcmToken = this.messagingService.token;
      console.log("browser Token:", fcmToken);
    }

    if (fcmToken) {
      params = params.append('fcmToken', fcmToken);
    }
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  getUsers(role?: any, id?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    if (role) {
      params = params.append('role', role);
    }
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  getCreateUser(role?: any, id?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    if (role) {
      params = params.append('role', role);
    }
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  // getCurrentUser() {
  //   let currentUser = firebase.auth().currentUser;
  //   if (currentUser) {
  //     let params = new HttpParams();
  //     params = params.append('_id', currentUser.uid);
  //     return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  //   } else (of(false));
  // }

  getAnonymousUser(): User {
    return JSON.parse(localStorage.getItem('anonymousUser'));
  }

  edit(data) {
    return this.http.put<User>(`${$REST_URI}/user`, data);
  }

  changePassword(data) {
    return this.http.put(`${$REST_URI}/client/password/change`, data)
  }

  changeRole(data) {
    return this.http.put(`${$REST_URI}/user/role`, data)
  }

  createAnonymousUser(): User {
    let user = {
      anonymousId: UUID.UUID(),
      languages: ['ENGLISH'],
      isAnonymous: true,
      name: null,
      avatar: null,
    }

    localStorage.removeItem('anonymousUser');

    localStorage.setItem('anonymousUser', JSON.stringify(user));

    return user as any;
  }

  updateAnonymousUser(data) {
    let user = this.getAnonymousUser();

    user.name = data.name;
    user.avatar = data.avatar;

    this._currentUser.next(user);

    console.log("user", user);

    localStorage.setItem('anonymousUser', JSON.stringify(user));
  }

  removeAnonymousUser() {
    localStorage.removeItem('anonymousUser');
  }

  getUserId(user: CurrentUser) {
    if (user?.isAnonymous) {
      return user?.anonymousId;
    } else {
      return user?.firebaseUid;
    }
  }

  logOutApi() {
    let data = {};
    console.log("logout api", data);
    return this.http.post(`${$REST_URI}/user/logout`, data);
  }

  logout(): boolean {
    try {
      this.removeUser();
      this.fireAuth.signOut();
      console.log("logout");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  saveUserSettings(userDetails: any) {
    return this.http.put<any>(`${$REST_URI}/user`, userDetails);
  }

  getBiblistUsers(query?: any, download?: boolean) {

    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    } else {
      params = params.append('disablePagination', 'true');
    }

    if (download) {
      params = params.append('isDownload', 'true');
    }

    return this.http.get<Biblist[]>(`${$REST_URI}/user/biblist/sieve`, { params });
  }

  getBiblistHistory(id?: any, level?: any, week?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    if (level) {
      params = params.append('level', level);
    }
    if (week) {
      params = params.append('week', week);
    }
    params = params.append('limit', '20');
    return this.http.get<any>(`${$REST_URI}/usersquestion/getQuestionHistory`, { params });
  }

  getBiblistAnalysis(query: any) {
    let params = new HttpParams();
    if (query) {
      // Object.keys(query).forEach(key => {
      //   params = params.append(key, query[key]);
      // });
      Object.keys(query).forEach(key => {
        if (key === 'filterData') {
          Object.keys(query[key]).forEach(key1 => {
            if (query[key][key1] !== null) {
              if (key1 === 'tag') {
                if (query[key][key1] !== "All") {
                  params = params.append(key1, query[key][key1]);
                }
              } else {
                params = params.append(key1, query[key][key1]);
              }
            }
          });
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    console.log("params", params);
    return this.http.get<any>(`${$REST_URI}/usersquestion/getBiblistAnalysis`, { params });
  }

  getBiblistRanking(query?: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    // } else {
    //   params = params.append('disablePagination', 'true');
    }
    console.log("parms Data", params);
    return this.http.get<any>(`${$REST_URI}/user/biblist/rank`, { params });
  }

  getBiblistActivity(id?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    return this.http.get<any>(`${$REST_URI}/usersquestion/getBiblistActivity`, { params });
  }

  getInvitationDetails() {
    return this.http.get<any>(`${$REST_URI}/user/invite/info`);
  }

  updateLevel(score: LevelProgress) {
    let user;
    this.currentUser.subscribe(res => {
      user = res;
    })

    if (user.level) {
      const userLevel = parseInt(user.level.split('-')[1]);
      const scoreLevel = parseInt(score.level.split('-')[1]);

      if (scoreLevel > userLevel) user.level = score.level;
    } else {
      user.level = score.level;
    }

    if (!user.isAnonymous) {
      this.userCollection.doc(user.id).set(user);
    } else {
      this.updateLocalUser(user);
    }
  }

  saveGender(gender: 'M' | 'F') {
    // const currentUser = firebase.auth().currentUser;
    // if (currentUser) {
    //   return this.userCollection.doc(currentUser.uid).set({ gender: gender }, { mergeFields: ['gender'] });
    // }
    let currentUser;
    this.currentUser.subscribe(res => {
      currentUser = res;
      if (currentUser) {
        currentUser.gender = gender;
      }
    });
    return this._currentUser.next(currentUser);
  }

  saveLanguage(language: string) {
    let currentUser;
    this.currentUser.subscribe(res => {
      currentUser = res;
      if (currentUser) {
        let userLanguage;
        if (language === "en") {
          userLanguage = "ENGLISH";
        } else {
          userLanguage = "GERMAN";
        }
        currentUser.language = userLanguage;
      }
    });
    return this._currentUser.next(currentUser);
  }

  saveProgress(progress: LevelProgress) {
    // let currentUser = firebase.auth().currentUser;
    // let userId = null;
    // if (currentUser) {
    //   userId = currentUser.uid;
    // } else {
    //   userId = this.currentUser.getValue().id;
    // }
    // return this.userCollection.doc(userId).collection('progresses').doc(progress.level).set(progress);
  }

  getProgress(level: string) {
    // let currentUser = this.currentUser;
    let currentUser;
    this.currentUser.subscribe(res => {
      currentUser = res;
    });
    let userId = null;
    if (currentUser) {
      userId = currentUser.uid;
    }
    // return this.userCollection.doc(userId).collection('progresses').doc(level).snapshotChanges();
  }

  deleteProgress(level: string) {
    // let currentUser = firebase.auth().currentUser;
    // let userId = null;
    // if (currentUser) {
    //   userId = currentUser.uid;
    // } else {
    //   userId = this.currentUser.getValue().id;
    // }
    // return this.userCollection.doc(userId).collection('progresses').doc(level).delete();
  }

  loginAsAnonymous() {
    // return firebase.auth().signInAnonymously();
  }

  setLocalUser() {

    // const user: UserMeta = {
    //   id: this.store.createId(),
    //   gender: 'M',
    //   isAnonymous: true,
    //   raffle: false,
    //   language: 'en',
    //   level: ''
    // }
    // localStorage.setItem('localUser', JSON.stringify(user));
    // this.currentUser.next(user);
    // return user;
  }

  updateLocalUser(user, gender?: string) {
    let currentUser;
    if (gender) {
      this.currentUser.subscribe(res => {
        currentUser = res;
        if (currentUser) {
          currentUser.gender = gender;
        }
      });
      localStorage.setItem('localUser', JSON.stringify(currentUser));
      this._currentUser.next(currentUser);
    } else {
      localStorage.setItem('localUser', JSON.stringify(user));
      this._currentUser.next(user);
    }
  }

  removeLocalUser() {
    localStorage.removeItem('localUser');
  }

  getLocalUser() {
    return JSON.parse(localStorage.getItem('localUser'));
  }

  getAll() {
    return this.userCollection.valueChanges();
  }

  setReferral(uid: string) {
    // let currentUser = firebase.auth().currentUser;

    // if (!currentUser && !localStorage.getItem('referral')) {
    //   localStorage.setItem('referral', uid);
    // }
  }

  getReferral(): string {
    return localStorage.getItem('referral');
  }

}
